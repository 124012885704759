<template>
  <div class="container">
    <div class="info">登录中...</div>
  </div>
</template>

<script>
import { casLoginCallback } from '@/api/user';
import Qs from 'qs'
export default {
  beforeCreate() {
    let ticket = this.$route.query.ticket
    if (!ticket) {
      const sps = window.location.href.substring(window.location.href.indexOf('?') + 1).split('#')
      for (let i = 0; i < sps.length; i++) {
        const s = sps[i]
        if (s.indexOf("ticket") >= 0) {
          const q = Qs.parse(s)
          ticket = q.ticket
          break
        }
      }
    }
    casLoginCallback('cas', ticket, window.location.origin + "/#/cas/callback").then(res => {
      this.$store.commit('user/setAccessToken', res.data.access_token)
      const sps = window.location.href.split('#')
      if (sps[0].indexOf('ticket=') >= 0) {
        window.location.href = '/'
      } else {
        this.$router.push('/')
      }
    }).catch(e => {
      console.log(e)
    })
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.info {
  margin-bottom: 40px;
}
</style>